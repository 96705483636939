/*
Template Name: Admin Template
Author: Wrappixel

File: js
*/
// ============================================================== 
// Auto select left navbar
// ============================================================== 
$(function () {
    var url = window.location;
    // var element = $('ul#sidebarnav a').filter(function () {
    //     return this.href == url;
    // }).addClass('active').parent().addClass('active');

    // while (true) {
    //     if (element.is('li')) {
    //         element = element.parent().addClass('in').parent().addClass('active').children('a').addClass('active');
            
    //     }
    //     else {
    //         break;
    //     }
    // }
    
    $(document).on('click', '#sidebarnav a', function (e) {
        var isClick = false;
        if (!$(this).hasClass("active")) {
                
                // hide any open menus and remove all other classes
                $("ul", $(this).parents("ul:first")).removeClass("in");
                $("a", $(this).parents("ul:first")).removeClass("active");
                
                // open our new menu and add the open class
                $(this).next("ul").addClass("in");
                $(this).addClass("active");
               
            }
            else if ($(this).hasClass("active")) {
                
                $(this).removeClass("active");
                $(this).parents("ul:first").removeClass("active");
                $(this).next("ul").removeClass("in");
            }
    })
    $(document).on('click', '#sidebarnav >li >a.has-arrow', function (e) {    
        e.preventDefault();
    });
});